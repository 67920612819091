import React from "react"
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {layoutOptions} from "../components/Content/RichText";
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({data, location, ...props}) => {
    const content = data.contentfulHomePage;
    const richText = documentToReactComponents(content.content.json, layoutOptions);
    const title = `${content.pageTitle}`;
    const description = `${content.metaDescription}`;
    const metaImage = `https:${content.metaImage.file.url}`;

    return (
        <Layout location={location}>
          <SEO title={title} />
          <Helmet>
          <meta name="image" content={metaImage} />
          <meta property="og:image" content={metaImage} />
          <meta name="twitter:image" content={metaImage} />
          </Helmet>
          {richText}
        </Layout>
    )
}

export default IndexPage;

export const indexQuery = graphql`
    query indexQuery {
        contentfulHomePage {
            content {
                json
            }
            canonicalLink
            metaDescription
            metaImage {
                file {
                    url
                }
            }
            metaKeywords
            navigationLabel
            pageTitle
        }
    }`
